<template>
  <!--<img alt="Vue logo" src="./assets/logo.png">-->
  <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <div class="mdui-progress" v-if="showprogress">
    <div class="mdui-progress-indeterminate"></div>
  </div>

  <div class="mdui-container mdui-theme-accent-blue ">

  <div class="mdui-textfield mdui-textfield-floating-label ">
    <label class="mdui-textfield-label">在这里输入歌名,歌手,专辑名和歌单id等</label>
  <input class="mdui-textfield-input textcentre" v-model="str" >
  </div>

    <div class="horizon">
  <button class="mdui-btn mdui-btn-raised mdui-ripple mdui-color-theme-accent" style="margin-right:6px" @click="searchlist" >
    <i class="mdui-icon material-icons">&#xe8b6;</i>
    搜索</button>
      <button class="mdui-btn mdui-btn-raised mdui-ripple mdui-color-theme-accent" @click="songlist">测试歌单</button>
    </div>

  </div>
 <br>


  <!--搜索音乐弹窗-->
    <!-- 显示/隐藏弹窗 -->
    <div v-if="showModal" class="div2 mdui-theme-accent-blue">
        <!-- 弹窗标题 -->
      <div class="horizon">
        <h5>搜索"{{str}}"的结果</h5>
        <button class="mdui-btn mdui-btn-icon mdui-color-theme-accent mdui-ripple btnclose" @click="closeModal">
          <i class="mdui-icon material-icons ">&#xe5cd;</i>
          </button>
      </div>
      <div>
        <!-- 列表 -->
        <ul>
          <li class="mdui-list-item mdui-ripple"  v-for="item in items" :key="item" @click="search(item.mid)">
            <div class="mdui-list-item-avatar"><img :src="item.cover" alt="无"/></div>
            <div class="mdui-list-item-content"  >
              <div class="mdui-list-item-title">歌名:{{item.songname}}</div>
              <div class="mdui-list-item-text mdui-list-item-one-line"><span
                  class="mdui-text-color-black">歌手:{{item.name}}</span> - 专辑:{{item.album}}
              </div>
            </div>

          </li>
        </ul>
        <!-- 关闭弹窗按钮 -->
      </div>

    </div>
  <!--歌单弹窗-->
  <div v-if="showModal2" class="div2 mdui-theme-accent-blue">

    <!-- 弹窗标题 -->
    <div class="horizon">
      <h5>搜索"{{str}}"的结果</h5>
      <button class="mdui-btn mdui-btn-icon mdui-color-theme-accent mdui-ripple btnclose" @click="closeModal">
        <i class="mdui-icon material-icons ">&#xe5cd;</i>
      </button>
    </div>

    <!-- 列表 -->
    <ul>
      <li class="mdui-list-item mdui-ripple"  v-for="item in songitems" :key="item" @click="search(item.mid)">

        <div class="mdui-list-item-content"  >
          <div class="mdui-list-item-title">歌名:{{item.name}}</div>
          <div class="mdui-list-item-text mdui-list-item-one-line"><span
              class="mdui-text-color-black">歌手:{{item.singer[0].name}}</span> - 专辑:{{item.album.name}}
          </div>
        </div>

      </li>
    </ul>
    <!-- 关闭弹窗按钮 -->


  </div>

  <br>
  <div class="mdui-container">
  <img :src="pic" alt="" >
  </div>

  <div class="horizon">
  <h4 style="margin-right: 15px;">歌手:{{singer}}</h4>
  <h4>专辑:{{album}}</h4>
  </div>


  <div class="bottom-fixed mdui-container">
    <aplayer
        ref="aplayer"
        :music="{
            title: title,
            artist: singer,
            src: song,
            pic: pic,
            lrc: lrc2,
            }"
        showLrc="true"
        theme="#7A88FF"
        autoplay="true"
    />

  </div>



</template>


<script >
import aplayer from 'vue3-aplayer';
import {defineComponent} from "vue";
import axios from "axios";
import {Base64} from "js-base64";

export default defineComponent({
  name:'App',
  data:function() {
return {
    str:"",
    song:"",
    //https://www.cnblogs.com/NExt-O/p/14173351.html
    pic:require("./assets/qq.png"),
    singer:"请先搜索",
    album:"请先搜索",
    title:"请先搜索 ",
    lrc2:"",
    songmid: "",
    showModal:false,
    items:[],
    showprogress:false,
    songitems:[],
    showModal2:false,


};
},
  components: {aplayer} ,


  methods: {



    closeModal() {
      this.showModal = false
      this.showModal2 = false
    },

    search:function (a) {

      this.showprogress=true;

      axios({
        method: "get", //接口方法
        url: "api/", //接口
        params: {
          mid:a
        }

      }).then((res) => {
        this.song = res.data.data.src;
        //this.song=this.song.replace(/http/g,'https')
        this.pic = res.data.data.cover;
        this.singer = res.data.data.name;
        this.album = res.data.data.album;
        this.title = res.data.data.songname;
        this.songmid = res.data.data.mid;


        this.showprogress=false
        this.showModal=false
        this.showModal2=false

        //获取歌词
        var jsonp;
        var lrcs;
        const url = 'api2/?g_tk=5381&inCharset=utf-8&outCharset=utf-8&needNewCode=1'
        axios.get(url, {
          params: {
            songmid: this.songmid
          }
        }).then((response) => {

          jsonp = response.data
          const startIndex = jsonp.indexOf('({');
          const endIndex = jsonp.indexOf('})');
          const jsonString = jsonp.slice(startIndex + 1, endIndex + 1);
          jsonp = JSON.parse(jsonString);
          lrcs = (jsonp.lyric);
          lrcs = Base64.decode(lrcs);
          this.lrc2=lrcs.replace(/&apos;/g,"'")

          //console.log(this.lrc2)
        }).catch((e) => {
          console.log(e)
        })

      }).catch((e) => {
        console.log(e)
        this.showprogress=false
        alert("音乐跑丢了,请刷新或稍后再试.")
      })

    },

    searchlist:function () {

      this.showprogress=true
      let str = this.str
      //var songmid2;
      axios({
        method: "get", //接口方法
        url: "api/?max=60", //接口
        params: {
          name: str
        }

      }).then((res) => {
        this.items = res.data.data;
        this.showModal = true
        this.showprogress=false
        //console.log(res.data.data)
      }).catch((e) => {
        console.log(e)
        this.showprogress=false
        alert("音乐跑丢了,请刷新或稍后再试.")
      })

    },

    songlist:function(){
      this.showprogress=true
      axios({
        method: "get", //接口方法
        url: "api3/?format=json&newsong=1&platform=jqspaframe.json", //接口
        params: {
            id:this.str //8454178511
        }

      }).then((res) => {
        this.songitems = res.data.data.cdlist[0].songlist;
        this.showModal2 = true
        this.showprogress=false
        //console.log(res.data.data.cdlist[0].songlist)
      }).catch((e) => {
        console.log(e)
        this.showprogress=false
        alert("请输入qq音乐歌单id,例如'8454178511'")
      })
    }



  },



});
</script>




<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(45deg, #7A88FF, #7AFFAF);
  background-size: cover;
  /*margin-top: 30px;*/
}

.div2 {
  position: fixed;
  top: 30px;
  left: 25%;
  right: 20%;
  bottom: 60px;
  transform: none;
  background-color: #90CAF9;
  padding: 10px;
  border-radius: 20px;
  max-width: 60%;
  width: 50%;
  overflow: auto;
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}


 img {
   width: 33%;
   border-radius: 15px;
 }

.bottom-fixed {
  border-radius: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.horizon{
  display: flex;
  justify-content: center;
}

.textcentre{
  text-align: center;
}

.btnclose {
  position: fixed;
  top: 5%;
  left: 25%;

  /* 其他样式 */
}

@media only screen and (max-width: 767px) {
  img {
    width: 70%;
  }
  .div2 {
    top: 20px;
    left: 30px;

    bottom: 20px;
    transform: none;
    border-radius: 0;
    overflow: auto;
    z-index: 10;
    max-width: 80%;
    width: 80%;
  }

  .btnclose {
    position: fixed;
    top: 5%;
    left: 12%;
  }
}

</style>
