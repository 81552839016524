import { createApp } from 'vue'
import App from './App.vue'
import aplayer from 'vue3-aplayer'

createApp(App).use(aplayer).mount('#app')



function block() {
    setInterval(() => {
        Function("debugger")();
    }, 1000);
}
try {
    block();
} catch (err) {''}

window.onkeydown = window.onkeyup = window.onkeypress = function (event) {

    if (event.keyCode == 123) {
        event.preventDefault()
        window.event.returnValue = false
    }
}

var detectDevTools = function() {
    var widthThreshold = window.outerWidth - window.innerWidth > 50;
    var heightThreshold = window.outerHeight - window.innerHeight > 50;
    if (heightThreshold && widthThreshold) {
        // 开发者工具已打开
        window.location.href = "about:blank";
    }
};
setInterval(detectDevTools, 20);






